import React from 'react';

const FamilyCareGiver = (
    <>
    <p>Are you managing a loved one’s care remotely? Or wanting to help manage your parent’s care and are feeling overwhelmed with the constantly changing details?</p>
    <p>Our revolutionary care-coordination service and Care Plan App saves you, your senior, and their care team significant time and effort in finding &amp; managing services that support a high quality of life at home. Symphony understands the complexity of caring for seniors in their homes, so we take a holistic approach to build an integrated care plan that identifies medical, residential, legal, financial, and social needs.</p>
    <p>We don’t stop with the plan, our real strength is in our time to help you retain the broad spectrum of services your senior may need, monitor their changing conditions and needs, and maintain close communication with your family and medical providers. Symphony is your partner in building the support you need to have peace of mind in helping your loved one live confidently and safely at home while reducing the risk of unnecessary exposure to the Covid-19 virus.</p>
    </>
  );

export default FamilyCareGiver;