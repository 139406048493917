import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

import HomeModalButton from "../components/Homepage/HomeModalButton"

// Import the content from a directory
import { IndependentSenior,
          FamilyCareGiver,
          ProfessionalAdvisor } from "../components/Homepage/HomeModalContent"

import HomeHero from "../components/HomeHero/HomeHero"
import Save from "../images/icons/Save"
import Identify from "../images/icons/Identify"
import Provide from "../images/icons/Provide"
import Coordinate from "../images/icons/Coordinate"

import UniqueCompleteCareBlock from "../components/Homepage/UniqueCompleteCareBlock"
import HoverCardBlock from "../components/HoverCardBlock/HoverCardBlock"
import CommunicationPlatform from "../components/Homepage/CommunicationPlatform"
import RealPeople from "../components/Homepage/RealPeople"
import CovidBlock from "../components/Homepage/CovidBlock"
import Assessment from "../components/Assessment/Assesment"
import Partners from "../components/Homepage/Partners"
import FooterContact from "../components/FooterContact/FooterContact"

//const SLIDE_COUNT = 5;
//const slides = Array.from(Array(SLIDE_COUNT).keys());


const IndexPage = ({ element }) => {
  const data = useStaticQuery(graphql`
  query {
  expertsGuideYou: file(relativePath: { eq: "experts-guide-you.jpg" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        ...GatsbyImageSharpFluid
      }
    }
  }
        }
        `)
  return (
  <Layout>
    <SEO title="Home" />
    <div>
      <HomeHero>
        <Container className="hero-container">
          <Row className="hero-flex-row">
            <Col style={{marginBottom: '100px'}}>
            <h1 className="hero-headline">
              Welcome Home
            </h1>
            <h2 className="hero-subhead">
            We are revolutionizing aging-in-place lifestyles using our compassion, technology, and deep understanding of seniors’ needs. Now in Arizona.
            </h2>
            </Col>
          </Row>
        </Container>
      </HomeHero>
    </div>
    <Container style={{
      marginTop: "-70px"
    }}>
    <Row style={{
                  height: '100%'
                }}>
              <Col
                className="vertical-center p-4"
                sm={{span: 10, offset: 1}}
              >
                <div className="rounded-big-card">
                <h3 className="home-roles">Playing One Of These Roles?</h3>
                <h4 className="home-roles-sub">Symphony is here for you. Take a look.</h4>
                <Row className="my-4 text-center" >
                  <Col sm={4} className="my-4">
                    <HomeModalButton
                    buttonText="Independent Senior"
                    modalTitle="Independent Senior"
                    modalText={IndependentSenior} />
                  </Col>
                  <Col sm={4} className="my-4">
                  <HomeModalButton
                    buttonText="Family Caregiver"
                    modalTitle="Family Caregiver"
                    modalText={FamilyCareGiver} />
                  </Col>
                  <Col sm={4} className="my-4">
                  <HomeModalButton
                    buttonText="Professional Advisor"
                    modalTitle="Professional Advisor"
                    modalText={ProfessionalAdvisor} />
                  </Col>
                </Row>
                </div>
              </Col>
            </Row>
            </Container>
    <Container className="my-4">
      <Row className="my-4">
        <Col className="text-center">
        <h1 className="home-icon-headline">We Will</h1>
        <div className="text-center" style={{
          width: '200px',
          height: '2px',
          borderBottom: '2px solid #7CB3FE',
          marginLeft: 'auto',
          marginRight: 'auto'
        }} />
        </Col>
        
      </Row>
      <Row>
        <Col className="text-center home-icon-block mb-4" sm={3}>
          <Save />
          <div>
            <p className="home-icon-text">Save You Time <br />and Effort</p>
          </div>
        </Col>
        <Col className="text-center home-icon-block mb-4" sm={3}>
          <Identify />
          <div>
            <p className="home-icon-text">Identify Best Local Providers</p>
          </div>
        </Col>
        <Col className="text-center home-icon-block mb-4" sm={3}>
          <Provide />
          <div>
            <p className="home-icon-text">Provide 24/7 Online Communication</p>
          </div>
        </Col>
        <Col className="text-center home-icon-block mb-4" sm={3}>
          <Coordinate />
          <div>
            <p className="home-icon-text">Coordinate &amp; Integrate Lifestyle Details</p>
          </div>
        </Col>
      </Row>
    </Container>

    <UniqueCompleteCareBlock />

    <Container>
    <HoverCardBlock
      fluid={data.expertsGuideYou.childImageSharp.fluid}
      alt="Let our experts guide you"
    >
      <p className="experts-card-text">Save time and effort,<br /> let our experts guide you.</p>
    </HoverCardBlock>
    </Container>

    <CommunicationPlatform />

    <RealPeople />

    <CovidBlock />

    <Assessment />
    
    <Partners />

    <FooterContact />

  </Layout>
)
}

// export const data = graphql`
// query {
//   expertsGuideYou: file(relativePath: { eq: "experts-guide-you.jpg" }) {
//     childImageSharp {
//       fluid(maxWidth: 800, quality: 80) {
//         ...GatsbyImageSharpFluid
//       }
//     }
//   }
// }
// `

export default IndexPage
