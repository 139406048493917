import React from "react";

const Family = ({
    style = {},
    fill = '#67923e',
    width = '48px',
    height = '48px',
    viewBox = '0 0 48 48',
    className = ''
}) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>

  <title>family</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M41.03226,3.09677a6.95922,6.95922,0,0,0-5.43871,11.31167L24.77419,25.22779v-11.338a6.96774,6.96774,0,1,0-1.54838,0v11.338L12.40651,14.40844a6.98541,6.98541,0,1,0-1.09477,1.09479L22.28089,26.47239a10.83869,10.83869,0,1,0,3.43827,0L36.68832,15.50323A6.96476,6.96476,0,1,0,41.03226,3.09677Zm-22.45161,3.871a5.41937,5.41937,0,1,1,9.71371,3.2928,5.23972,5.23972,0,0,0-1.92348-1.711A3.42878,3.42878,0,1,0,21.629,8.54926a5.23654,5.23654,0,0,0-1.92318,1.71136A5.38529,5.38529,0,0,1,18.58065,6.96774ZM1.54839,10.06452a5.41937,5.41937,0,1,1,9.71371,3.2928,5.23966,5.23966,0,0,0-1.92348-1.711A3.42877,3.42877,0,1,0,4.59676,11.646a5.23648,5.23648,0,0,0-1.92317,1.71135A5.38526,5.38526,0,0,1,1.54839,10.06452ZM33.29032,37.16129A9.23474,9.23474,0,0,1,31.317,42.866,7.50694,7.50694,0,0,0,26.78,37.48746a4.35255,4.35255,0,1,0-5.56,0A7.50685,7.50685,0,0,0,16.6831,42.866a9.28519,9.28519,0,1,1,16.60722-5.70472Zm12.0363-23.804a5.23961,5.23961,0,0,0-1.92349-1.711,3.42877,3.42877,0,1,0-4.74185-.00023,5.23651,5.23651,0,0,0-1.92318,1.71135,5.41929,5.41929,0,1,1,8.58852-.00007Z"/>
    </g>
  </g>
</svg>
</div>
    )
  }

export default Family