import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Container, Row, Col, Button } from "react-bootstrap"
import Img from "gatsby-image"

const UniqueCompleteCareBlock = () => {
  const data = useStaticQuery(graphql`
    query {
      uniqueCare: file(
        relativePath: { eq: "Symphonys-care-coordination-plan.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Container className="big-y-margin">
      <Row>
        <Col sm={4}>
          <Img
            fluid={data.uniqueCare.childImageSharp.fluid}
            alt="Unique Complete Care Approach"
          />
        </Col>
        <Col className="text-center text-md-right">
          <h1 className="uniqueCare mb-4 text-center text-md-right">
            <span
              style={{
                fontWeight: "500",
              }}
            >
              <em>Unique</em>
            </span>{" "}
            Complete-Care Approach
          </h1>
          <p className="uniqueCare mb-4 text-center text-md-right">
          Symphony looks at the whole person in order to orchestrate high quality services for seniors living at home. Our revolutionary care-coordination service and Care Plan App guides seniors, families &amp; professional advisors to identify, manage, and monitor ongoing needs to support the best quality of independent life for seniors aging-in-place and peace of mind for family with all the details in a single location with the most up-to-date information available.
          </p>
          <Link to="/complete-care-approach">
          <Button className="uniqueCareButton">Learn More</Button>
          </Link>
        </Col>
      </Row>
    </Container>
  )
}

export default UniqueCompleteCareBlock
