import React from "react";

const Coordinate = ({
    style = {},
    fill = '#7cb3fe',
    width = '1127px',
    height = '112px',
    viewBox = '0 0 112 112.001',
    className = ''
}) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <circle class="cls-1" cx="74.021" cy="42.854" r="2.922"/>
        <circle class="cls-1" cx="74.021" cy="58.43799" r="2.922"/>
        <circle class="cls-1" cx="60.38599" cy="42.854" r="2.922"/>
        <circle class="cls-1" cx="60.38599" cy="58.43799" r="2.922"/>
        <circle class="cls-1" cx="46.75" cy="42.854" r="2.922"/>
        <circle class="cls-1" cx="46.75" cy="58.43799" r="2.922"/>
        <circle class="cls-1" cx="46.75" cy="74.021" r="2.922"/>
        <circle class="cls-1" cx="33.11499" cy="42.854" r="2.922"/>
        <circle class="cls-1" cx="33.11499" cy="58.43799" r="2.922"/>
        <circle class="cls-1" cx="33.11499" cy="74.021" r="2.922"/>
        <circle class="cls-1" cx="19.479" cy="42.854" r="2.922"/>
        <circle class="cls-1" cx="19.479" cy="58.43799" r="2.922"/>
        <circle class="cls-1" cx="19.479" cy="74.021" r="2.922"/>
        <path class="cls-1" d="M71.642,66.229a7.027,7.027,0,0,0-5.413,2.922,7.03394,7.03394,0,0,0-5.415-2.922q-.056.00074-.11182.00251A6.364,6.364,0,0,0,54.542,72.793c0,4.118,4.434,9.346,10.78,12.689a1.92887,1.92887,0,0,0,1.812,0c6.347-3.339,10.78-8.559,10.78-12.689q.00156-.05082.00232-.10169A6.36892,6.36892,0,0,0,71.642,66.229ZM66.23,81.54c-4.839-2.805-7.792-6.63-7.792-8.742q-.00678-.07562-.00879-.15161A2.452,2.452,0,0,1,60.814,70.129c1.51995,0,2.961,1.877,3.571,3.654a1.948,1.948,0,0,0,3.685,0c.611-1.772,2.072-3.654,3.572-3.654q.084.00192.16785.00964A2.44593,2.44593,0,0,1,74.022,72.798C74.022,74.917,71.069,78.74,66.23,81.54Z"/>
        <path class="cls-1" d="M87.657,7.792H81.813V1.948A1.948,1.948,0,0,0,79.865,0H72.073a1.948,1.948,0,0,0-1.948,1.948V17.531a1.948,1.948,0,0,0,1.948,1.948h7.792a1.948,1.948,0,0,0,1.948-1.948V11.692h5.843A1.948,1.948,0,0,1,89.6,13.636V25.323a1.94733,1.94733,0,0,1-1.93262,1.94684L87.659,27.269l-.02.002H5.866l-.02-.002-.009.00092A1.94743,1.94743,0,0,1,3.9,25.323V13.636a1.948,1.948,0,0,1,1.944-1.944H7.792v-3.9H5.844A5.844,5.844,0,0,0,0,13.636V25.323a5.8411,5.8411,0,0,0,3.896,5.50433V83.761a9.74,9.74,0,0,0,9.74,9.739H79.865l.004.001a9.74,9.74,0,0,0,9.74-9.74V30.8255A5.84112,5.84112,0,0,0,93.5,25.323V13.636A5.844,5.844,0,0,0,87.657,7.792Zm-9.736,7.787h-3.9V3.9h3.9Zm1.944,74.026H13.64a5.844,5.844,0,0,1-5.844-5.844V31.167H85.709V83.761A5.844,5.844,0,0,1,79.865,89.605Z"/>
        <path class="cls-1" d="M13.636,19.479h7.792a1.948,1.948,0,0,0,1.948-1.948V12H66.824V8H23.376V1.948A1.948,1.948,0,0,0,21.428,0H13.636a1.948,1.948,0,0,0-1.948,1.948V17.531A1.948,1.948,0,0,0,13.636,19.479ZM15.588,3.9h3.896V15.579h-3.9Z"/>
      </g>
    </g>
  </g>
</svg>
</div>
    )
}
export default Coordinate