import React from "react";

const Residential = ({
    style = {},
    fill = '#107583',
    width = '49px',
    height = '47px',
    viewBox = '0 0 49 47',
    className = ''
}) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>

  <title>residential</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M26.35989.53554a2.41593,2.41593,0,0,0-3.03706.00271L.50775,20.1288c-.83455.7166-.61737,1.30291.48263,1.30291H3.22091a2.00588,2.00588,0,0,1,2,2V45.22608a2.01383,2.01383,0,0,0,2,2.00925H42.45932a2.00588,2.00588,0,0,0,2-2V23.43171a2.00588,2.00588,0,0,1,2-2h2.32427c1.1,0,1.31614-.58509.48031-1.3002ZM41.93917,43.26182a2.00519,2.00519,0,0,1-2,1.99943l-30.0081-.00856a2.00658,2.00658,0,0,1-2-2.00057V23.7693a2.00588,2.00588,0,0,1,2-2h30.0081a2.00588,2.00588,0,0,1,2,2ZM7.18983,19.51039c-1.1,0-1.31615-.5851-.48032-1.30023l16.706-14.29355a2.42108,2.42108,0,0,1,3.03934,0L43.16074,18.21016c.83582.71512.61967,1.30023-.48033,1.30023Z"/>
        <circle class="cls-1" cx="31.97967" cy="31.80954" r="1.70602"/>
        <circle class="cls-1" cx="25.14639" cy="31.80954" r="1.70602"/>
        <circle class="cls-1" cx="18.31395" cy="31.80954" r="1.70602"/>
      </g>
    </g>
  </g>
</svg>
</div>
    )
}
export default Residential