import React from "react";

const Save = ({
    style = {},
    fill = '#7cb3fe',
    width = '112px',
    height = '112px',
    viewBox = '0 0 95 111.95',
    className = ''
}) => {
    return (
        <div style={{
          display: 'flex',
          justifyContent: 'center'
        }}>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls-1" d="M46.79078,61.81778c-5.523,0-10.018-3.743-10.018-8.348a1.67,1.67,0,0,1,3.339,0c0,2.762,3.005,5.009,6.679,5.009s6.679-2.247,6.679-5.009-3.005-5.009-6.679-5.009c-5.523,0-10.018-3.743-10.018-8.348s4.495-8.349,10.018-8.349,10.018,3.743,10.018,8.348a1.67,1.67,0,0,1-3.339,0c0-2.762-3.005-5.009-6.679-5.009s-6.679,2.247-6.679,5.009,3.005,5.009,6.679,5.009c5.523,0,10.018,3.743,10.018,8.348S52.31378,61.81778,46.79078,61.81778Z"/>
      <path class="cls-1" d="M46.79078,35.10278a1.67,1.67,0,0,1-1.67-1.67v-5.008a1.67,1.67,0,1,1,3.339-.08154q.001.04077,0,.08154v5.009A1.67,1.67,0,0,1,46.79078,35.10278Z"/>
      <path class="cls-1" d="M46.79078,66.82578a1.67,1.67,0,0,1-1.67-1.67v-5.008a1.67,1.67,0,0,1,3.339-.08154q.001.04077,0,.08154v5.009A1.67,1.67,0,0,1,46.79078,66.82578Z"/>
      <path class="cls-1" d="M46.79078,76.84378a30.054,30.054,0,1,1,30.054-30.054h0A30.054,30.054,0,0,1,46.79078,76.84378Zm0-56.768a26.714,26.714,0,1,0,26.714,26.714,26.714,26.714,0,0,0-26.714-26.714Z"/>
      <path class="cls-1" d="M46.79078,13.39778a1.67,1.67,0,0,1-1.67-1.67V1.71078a1.67,1.67,0,0,1,3.339-.08154q.001.04077,0,.08154v10.018A1.67,1.67,0,0,1,46.79078,13.39778Z"/>
      <path class="cls-1" d="M46.79078,93.54078a1.67,1.67,0,0,1-1.67-1.67v-10.017a1.67,1.67,0,0,1,3.339-.08154q.001.04077,0,.08154v10.018A1.67,1.67,0,0,1,46.79078,93.54078Z"/>
      <path class="cls-1" d="M29.25978,18.08578a1.67,1.67,0,0,1-1.446-.835l-5.009-8.665A1.67,1.67,0,1,1,25.66918,6.868l.0276.04779,5.009,8.67a1.67,1.67,0,0,1-.611,2.281,1.65,1.65,0,0,1-.835.224Z"/>
      <path class="cls-1" d="M69.33077,87.49677a1.67,1.67,0,0,1-1.446-.835l-5.009-8.665a1.67,1.67,0,0,1,2.86438-1.71783l.02762.04783,5.009,8.682a1.67,1.67,0,0,1-1.446,2.5Z"/>
      <path class="cls-1" d="M16.40278,30.92978a1.67,1.67,0,0,1-.835-.224l-8.662-5.009A1.67,1.67,0,0,1,8.528,22.77718l.04779.0276,8.665,5.009a1.67,1.67,0,0,1-.838,3.116Z"/>
      <path class="cls-1" d="M85.82378,71.00079a1.67005,1.67005,0,0,1-.831-.224l-8.682-5.009A1.67,1.67,0,1,1,77.933,62.84817l.04783.02762,8.665,5.009a1.67,1.67,0,0,1-.838,3.116Z"/>
      <path class="cls-1" d="M11.72878,48.45979H1.71078a1.67,1.67,0,0,1-.08154-3.339q.04077-.001.08154,0h10.018a1.67,1.67,0,0,1,.08154,3.339Q11.76955,48.46076,11.72878,48.45979Z"/>
      <path class="cls-1" d="M91.87179,48.45979h-10.018a1.67,1.67,0,0,1-.08154-3.339q.04077-.001.08154,0h10.018a1.67,1.67,0,0,1,.08154,3.339Q91.91256,48.46076,91.87179,48.45979Z"/>
      <path class="cls-1" d="M7.75478,71.00079a1.67,1.67,0,0,1-.835-3.116l8.662-5.009a1.67,1.67,0,1,1,1.71779,2.86439l-.04779.0276-8.667,5.009a1.67009,1.67009,0,0,1-.831.224Z"/>
      <path class="cls-1" d="M77.17878,30.92978a1.67,1.67,0,0,1-.838-3.116l8.682-5.009a1.67,1.67,0,1,1,1.71783,2.86437l-.04783.02763-8.665,5.009A1.67006,1.67006,0,0,1,77.17878,30.92978Z"/>
      <path class="cls-1" d="M24.25078,87.49679a1.67,1.67,0,0,1-1.446-2.5l5.009-8.682a1.67,1.67,0,0,1,2.91963,1.62216l-.02763.04784-5.009,8.665A1.67,1.67,0,0,1,24.25078,87.49679Z"/>
      <path class="cls-1" d="M64.32178,18.08578a1.65,1.65,0,0,1-.835-.224,1.67,1.67,0,0,1-.611-2.281l5.009-8.665A1.67,1.67,0,0,1,70.80439,8.538l-.0276.04779-5.009,8.665A1.67,1.67,0,0,1,64.32178,18.08578Z"/>
    </g>
  </g>
</svg>
</div>
    )
}

export default Save
