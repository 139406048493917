import React from "react"
import { Container, Row, Col, CardDeck, Card } from "react-bootstrap"
import Family from "../../images/icons/family"
import Financial from "../../images/icons/financial"
import Residential from "../../images/icons/residential"
import Medical from "../../images/icons/medical"

const CommunicationPlatform = () => (
    <Container className="big-y-margin">
        <Row>
            <Col>
            <h1 className="uniqueCare mb-4" style={{
            textAlign: 'center'
        }}>
            One Partner, Multiple Solutions:
        </h1>
        <h2 style={{textAlign: 'center', marginBottom: '4rem'}}>Life is more than just medical.  Our Care Team manages the details in four service categories to keep seniors independently aging-in-place.</h2>
            </Col>
        </Row>
        <Row>
            <Col className="col-12">
            <CardDeck>
                <Card className="homeCard">
                    <Medical />
                    <Card.Body>
                        <Card.Title 
                            className="homeCardTitle"
                            style={{
                            color: '#9F2214'
                        }}>
                            Health &amp; Medical
                        </Card.Title>
                        <hr />
                        <Card.Text className="homeCardBody">
                        Services that support good health by using the best-suited health insurance plans, physicians and other professionals; monitoring medication use; advocating for cost effective patient care; encouraging exercise and healthy habits.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="homeCard">
                    <Residential />
                    <Card.Body>
                        <Card.Title
                            className="homeCardTitle"
                            style={{
                                color: '#0E7583'
                            }}
                        >
                            Residential &amp; Routine
                        </Card.Title>
                        <hr />
                        <Card.Text className="homeCardBody">
                        Services that clarify roles for caretakers and homemakers; home and garden maintenance contractors; grocery shopping and meal prep; pet care; transportation teams; home nursing, palliative, dementia, and hospice care.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="homeCard">
                    <Financial />
                    <Card.Body>
                        <Card.Title
                            className="homeCardTitle"
                            style={{
                                color: '#3B5272'
                            }}
                        >
                            Financial &amp; Legal
                        </Card.Title>
                        <hr />
                        <Card.Text className="homeCardBody">
                        Services that simplify routine financial & legal needs, such as life-care planning, power of attorney documents, medical claim reconciliation, automated on-line bill-paying, credit card monitoring, budgeting and prevention of fraud.
                    </Card.Text>
                    </Card.Body>
                </Card>
                <Card className="homeCard">
                    <Family />
                    <Card.Body>
                        <Card.Title
                            className="homeCardTitle"
                            style={{
                                color: '#67923D'
                            }}
                        >
                            Family &amp; Social
                        </Card.Title>
                        <hr />
                        <Card.Text className="homeCardBody">
                        Our Care Team uses the Care Plan App to organize all the details to keep families informed. Services also include coaching families; mediating conflicting views; services needed to maintain social connections, activities, and spiritual support.
                    </Card.Text>
                    </Card.Body>
                </Card>
            </CardDeck>
            </Col>
        </Row>
    </Container>
)

export default CommunicationPlatform