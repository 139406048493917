import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./Homepage.module.css"

const CovidBlock = () => (
    <>
    <Container className={`${styles.covidBlock} py-4 big-y-margin d-none d-lg-block`} >
        <Row style={{height: '100%', alignContent: 'center'}} className="center-vertical">
            <Col sm={{span: 7, offset: 1}}>
                <div className="rounded-big-card"
                style={{
                    padding: '20px'
                }}>
                <h2 className={styles.covidHeadline}>Supporting seniors in their homes is a challenge… Covid-19 just made it much harder.</h2>
                <p className={styles.supportingSeniors}>
                Most seniors prefer the quality and independence of staying in their homes over group residential living. With the Covid-19 pandemic, public health officials call for protecting all seniors from the disease by limiting access to others as much as possible. In these unique times, Symphony is pivotal in assisting families, care providers, and seniors with coordinated care when in-person visits may be limited. Symphony’s Care Plan App gives up-to-date information amidst changing conditions so care teams can safely manage essential needs during the pandemic.</p>
                </div>
            </Col>
        </Row>
    </Container>

    <Container className="d-lg-none big-y-margin">
        <Row>
            <Col>
            <Row style={{height: '100%', alignContent: 'center'}} className="center-vertical">
            <Col sm={12}>
                <div className="rounded-big-card"
                style={{
                    padding: '20px'
                }}>
                <h2 className={styles.covidHeadline}>Supporting seniors in their homes is a challenge… Covid-19 just made it much harder.</h2>
                <p className={styles.supportingSeniors}>
                Most seniors prefer the quality and independence of staying in their homes over group residential living. With the Covid-19 pandemic, public health officials call for protecting all seniors from the disease by limiting access to others as much as possible. In these unique times, Symphony is pivotal in assisting families, care providers, and seniors with coordinated care when in-person visits may be limited. Symphony’s Care Plan App gives up-to-date information amidst changing conditions so care teams can safely manage essential needs during the pandemic..</p>
                </div>
            </Col>
        </Row>
            </Col>
        </Row>
    </Container>
    </>
)

export default CovidBlock