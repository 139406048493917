import React from "react";

const Identify = ({
    style = {},
    fill = '#7cb3fe',
    width = '112px',
    height = '106px',
    viewBox = '0 0 112 105.842',
    className = ''
}) => {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
        <svg 
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    className={className}
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M95.83446,59.9624l-.06836-.273-.15625-.23437a3.82727,3.82727,0,0,0-2.70849-1.665,6.72827,6.72827,0,0,0-2.33222.14215c1.1593-6.16278.671-6.71863.19159-7.23932a1.77208,1.77208,0,0,0-1.49365-.5498c-1.585.0039-6.3042.01562-14.67822,14.16552-5.80664-3.04638-12.22461-.69531-14.57959.3794-2.22412-1.50684-8.08789-5.44971-9.9541-6.41992a15.73017,15.73017,0,0,1-1.50489-1.23389c-2.25927-2.00928-5.07177-4.51025-13.97851-4.51025-10.64942,0-21.21192,14.206-22.666,16.23486L.64989,77.20117a1.6249,1.6249,0,1,0,1.9502,2.59961l11.668-8.75146.14941-.21338c.105-.15088,10.624-15.062,20.1543-15.062,7.6709,0,9.77978,1.87548,11.81884,3.689A17.53068,17.53068,0,0,0,48.32616,61.022l.17481.10157c1.56006.77978,8.08154,5.16064,10.46289,6.78271l.82812.56445.876-.48681c.07519-.04114,7.28992-3.94776,12.997-.43622C67.7503,70.712,62.041,73.61591,60.75731,73.99805c-1.37939-.2041-8.44238-2.26319-14.72168-4.21143a1.62473,1.62473,0,0,0-.96289,3.10352c4.23389,1.31445,14.24561,4.3706,15.76075,4.3706.457,0,1.84716,0,17.96826-8.793l.25293-.17089c3.94433-3.2378,10.64551-7.61231,13.30957-7.29981-1.23,2.13818-5.86572,7.48877-10.09033,11.90381C73.84228,77.55322,64.269,82.42822,62.74364,82.606c-.76513,0-5.07324-.854-8.87451-1.60742C41.9829,78.64258,37.49267,77.896,35.977,78.38867l-.42041.13379c-2.45117.77246-6.15527,1.939-11.396,9.563a1.62472,1.62472,0,1,0,2.67774,1.84082c4.60742-6.70117,7.54785-7.62744,9.69482-8.30371l.37793-.12451c1.4878-.25147,10.77637,1.58838,16.32617,2.68847A91.47894,91.47894,0,0,0,62.74364,85.856c2.53321,0,15.75244-7.1543,21.31836-10.23l.21533-.11914.1709-.17774C96.52978,62.74316,96.04247,60.79443,95.83446,59.9624ZM77.9638,65.00757c3.83081-6.28656,7.34057-10.26611,9.98443-11.32788-.17395,1.41754-.54773,3.58252-.9397,5.51648A51.92814,51.92814,0,0,0,77.9638,65.00757Z"/>
        <path class="cls-1" d="M64.15038,55.77393A27.91542,27.91542,0,0,0,92.02923,28.21661a1.62918,1.62918,0,0,0,0-.65979A27.88378,27.88378,0,1,0,64.15038,55.77393ZM43.04754,15.187h7.27624a46.9241,46.9241,0,0,0-1.6189,11.07471H39.57317A24.46663,24.46663,0,0,1,43.04754,15.187ZM88.72808,26.26172h-9.1322A46.9241,46.9241,0,0,0,77.977,15.187h7.27661A24.466,24.466,0,0,1,88.72808,26.26172ZM85.25365,40.58643H77.9771a46.92691,46.92691,0,0,0,1.61878-11.07471h9.1322A24.46693,24.46693,0,0,1,85.25365,40.58643Zm-22.72827,3.25V52.289c-2.9942-.84357-5.681-3.97046-7.60077-8.45257Zm-8.787-3.25a43.20128,43.20128,0,0,1-1.77911-11.07471h10.5661V40.58643ZM65.77538,52.289V43.83643h7.60077C71.45641,48.31854,68.76958,51.44543,65.77538,52.289Zm0-11.70257V29.51172h10.5661a43.20128,43.20128,0,0,1-1.77911,11.07471Zm10.5661-14.32471H65.77538V15.187h8.78687A43.2037,43.2037,0,0,1,76.34148,26.26172ZM65.77538,11.937V3.48492c2.99414.84357,5.681,3.97022,7.60059,8.45209Zm-3.25-8.45209V11.937H54.92479C56.84441,7.45514,59.53124,4.32849,62.52538,3.48492Zm0,11.70209V26.26172H51.95928A43.20325,43.20325,0,0,1,53.73852,15.187ZM48.70488,29.51172a46.92742,46.92742,0,0,0,1.61878,11.07471H43.04748a24.46753,24.46753,0,0,1-3.47431-11.07471ZM45.39531,43.83643h5.9903a25.95007,25.95007,0,0,0,3.89252,7.02813A24.73387,24.73387,0,0,1,45.39531,43.83643Zm27.62726,7.02813a25.95086,25.95086,0,0,0,3.89258-7.02813h5.99054A24.73528,24.73528,0,0,1,73.02257,50.86456Zm9.883-38.92755H76.915a25.94868,25.94868,0,0,0-3.89246-7.02777A24.73468,24.73468,0,0,1,82.90557,11.937ZM55.27819,4.90924a25.94859,25.94859,0,0,0-3.8924,7.02777H45.39544A24.73324,24.73324,0,0,1,55.27819,4.90924Z"/>
      </g>
    </g>
  </g>
</svg>
</div>
    )
}

export default Identify