import React from "react"
import { Modal, Row, Col } from "react-bootstrap"

import styles from "./Homepage.module.css"

function HomeModal(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className={styles.homeModal}
        >
            <Modal.Header closeButton>
                <Row>
                    <Col>
                    <div>
                        <h3 className={styles.modalHeader}>{ props.modalTitle }</h3>
                    </div>
                    </Col>
                </Row>
            </Modal.Header>
            <Modal.Body>
                <p className={styles.modalBody}>{ props.modalText }</p>
            </Modal.Body>
        </Modal>
    )
}

export default HomeModal;