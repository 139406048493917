import React from "react"
import { Button } from "react-bootstrap"
import HomeModal from "./HomeModal"

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleRight } from "@fortawesome/pro-light-svg-icons"

function Modal(props) {
  const [modalShow, setModalShow] = React.useState(false)

  return (
    <>
      <Button
        variant="primary blue-btn btn-md"
        onClick={() => setModalShow(true)}
      >
        <span style={{
            marginRight: '10px'
        }}>
          <FontAwesomeIcon
            icon={faArrowCircleRight}
            className="arrow-icon arrowCircle"
            aria-hidden="true" 
          />
        </span>

        {props.buttonText}
      </Button>

      <HomeModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        modalTitle={props.modalTitle}
        modalText={props.modalText}
      />
    </>
  )
}

export default Modal
