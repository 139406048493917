import React from 'react';

const ProfessionalAdvisor = (
    <>
    <p>Are you working with a senior client that is facing new challenges that you would like to help them navigate through? Also juggling a busy schedule and don’t have the bandwidth to guide them through the many steps needed to remain in their homes safely?</p>
    <p>Symphony is a revolutionary care-coordination service and Care Plan App. Professional advisors, such as elder lawyers, wealth managers and tax advisors now have a trusted partner in caring for your senior client’s unique needs. Symphony understands the complexity of caring for seniors in their homes, so we take a holistic approach to build an integrated care plan that identifies medical, residential, legal, financial, and social needs. We don’t stop with the plan, our real strength is in our experience and the time we take to help our clients retain the broad spectrum of services they may need, monitor their changing conditions and needs, and maintain close communication with their family, medical providers, and advisors.</p>
    <p>Professional advisors benefit from knowing that their clients are safe and secure in their homes, and have reassurance knowing that Symphony’s Care Team is instating provider recommendations and instructions as well as monitoring for any changes in status. Our Care Plan App keeps all important information accessible 24/7 and up to date. Rest assured that your clients are well cared for with Symphony as your partner in supporting senior independence. </p>
    </>
  );

export default ProfessionalAdvisor;